.jessie-paginate-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: unset;
}

.paginate-break {
  color: #323232;
  font-weight: 500;
  font-size: 1rem;
}

.paginate-disabled {
  cursor: not-allowed;
  pointer-events: all !important;
  opacity: 0.65;
}

.paginate-disabled a button {
  color: #fff !important;
  background-color: #d0dae4 !important;
  border-color: #d0dae4 !important;
}

.jessie-break-link {
  text-decoration: none;
}

.jessie-page {
  margin: 0 0.3rem;
}

.jessie-link,
.jessie-break-link {
  color: #323232;
  font-weight: 500;
  font-size: 1rem;
  text-decoration: none;
}

.jessie-active {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #016cba;
  color: #fff;
  border-radius: 50%;
  text-decoration: none;
}

.jessie-active-link {
  color: #fff;
}

@media only screen and (max-width: 351px) {
  .paginate-break {
    display: none;
  }
}
