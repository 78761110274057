.jessie-rating span {
  border: 1.5px solid #adb5bd;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.error span {
  border: 1.5px solid red;
}

.jessie-rating span::before {
  top: unset;
  left: unset;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
