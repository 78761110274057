.Collapsible {
  color: #323232;
  background: #ffffff;
  border-radius: 25px;
  border: 1px solid #016cba;
  padding: 2rem 1.5rem;
  font-weight: 500;
  font-size: 15px;
}

.Collapsible__trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Collapsible__trigger.is-open svg {
  transform: rotate(180deg);
}

.Collapsible__trigger.is-open {
  color: #016cba;
}

.Collapsible__contentOuter {
  text-align: left;
  padding-top: 1rem;
  font-size: 14px;
  font-weight: 300;
}
